.pdf-container {
    width: 100%;
    padding: 0;
    
}

.pdf-row {
    display: flex;
    justify-content: center;
}

.pdf-col {
    width: 100%;
    max-height: 80vh; /* Limita la altura del contenedor al 80% del viewport */
    display: flex;
    justify-content: center;
    border: solid 1px gray;
    border-radius: 0.5%;
    overflow: auto; /* Permite que aparezca un scroll si el contenido es muy grande */
}

.pdf-col canvas {
    width: 100% !important;
    height: auto !important;
}

.pdf-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.pdf-controls .text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;  /* Espacio entre los botones y el texto */
}

.pdf-controls .page-info {
    margin: 0 10px;
    font-size: 1.2em;
}

.pdf-controls button {
    display: flex;
    align-items: center;
}

.pdf-controls button svg {
    margin: 0 5px;
}